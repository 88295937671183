import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Package } from '../../../Classes/Package';
import AdviceLineMatrix from '../../../components/AdviceLine/AdviceLine';
import Banner from '../../../components/Banner/Banner';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import PackageSubscription from '../../../components/PackageSubscription/PackageSubscription';
import * as styles from './Subscribe.module.scss';

interface Props {
  PackageID: string;
  Length: string;
  AdviceLine: string;
  AdviceLineLength: string;
  Packages: Package[];
}

const Subscribe: React.FC<Props> = ({ PackageID, Length, AdviceLine, AdviceLineLength, Packages }) => {
  const [pack, setPackage] = useState<Package>({} as Package);

  useEffect(() => {
    if (PackageID && Length && AdviceLine && AdviceLineLength)
      GetData();
    else
      navigate("/Subscribe?PackageID=0&PackageLength=0&AdviceID=0&AdviceLength=0");
  }, [PackageID, Length, AdviceLine, AdviceLineLength])

  const GetData = async () => {
    if (PackageID) {
      let chosenPack = Packages.find(p => p.ID === parseInt(PackageID))
      if (chosenPack)
        setPackage(chosenPack);
    }
  }

  const GetTrail = () => {
    var trail: { To: string, Text: string }[] = [{ To: "", Text: "Subscribe" }];
    if (PackageID && parseInt(PackageID)) {
      trail[0].To = "/Subscribe";
      trail.push({ To: "", Text: pack.Title });

      if (Length && parseInt(Length)) {
        trail[1].To = `/Subscribe?PackageID=${PackageID}&PackageLength=0&AdviceID=0&AdviceLength=0`;
        trail.push({ To: "", Text: `${Length} Month${Length == "1" ? "" : "s"}` });
      }
    }

    return trail;
  }

  return (<>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>PrimeHR :: Subscribe</title><meta charSet='utf-8' />
    </Helmet>

    <Banner />

    <Breadcrumb Trail={GetTrail()} />

    <div className={styles.Subscribe}>
      <div className={styles.SubscribeInner}>
        <div className={styles.SubscribeMain}>
          <PackageSubscription PackageID={PackageID} Length={Length} AdviceLine={AdviceLine} AdviceLineLength={AdviceLineLength} isPortal={false} Packs={Packages} />
        </div>
      </div>

      <div className={styles.SubscribeSide}>
        <h1 className={styles.Title}>Advice Line (Optional): </h1>

        <AdviceLineMatrix Disabled={GetTrail().length !== 3} OnChange={(ID, Duration) => navigate(`/Subscribe?PackageID=${PackageID}&PackageLength=${Length}&AdviceID=${ID}&AdviceLength=${Duration}`)} ID={AdviceLine ? parseInt(AdviceLine) : 0} Duration={AdviceLineLength ? parseInt(AdviceLineLength) : 0} />

        <Link className={`${styles.Button} ${GetTrail().length !== 3 || (AdviceLine && parseInt(AdviceLine) && AdviceLineLength && !parseInt(AdviceLineLength)) ? styles.Disabled : ""}`} to={`/Receipt?PackageID=${PackageID}&PackageLength=${Length}&AdviceID=${AdviceLine ? AdviceLine : "0"}&AdviceLength=${AdviceLineLength ? AdviceLineLength : "0"}`}>
          Continue
          <i><FontAwesomeIcon icon={faArrowRight} /></i>
        </Link>
      </div>
    </div>
  </>
  );
};

export default Subscribe;
