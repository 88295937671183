// extracted by mini-css-extract-plugin
export var Button = "PackageSubscription-module--Button--f8408";
export var Disabled = "PackageSubscription-module--Disabled--d5a4f";
export var Empty = "PackageSubscription-module--Empty--0f9af";
export var Highlight = "PackageSubscription-module--Highlight--4732b";
export var Loading = "PackageSubscription-module--Loading--0df5d";
export var LoadingWheel = "PackageSubscription-module--LoadingWheel--a6ae9";
export var PackageBlock = "PackageSubscription-module--PackageBlock--008d4";
export var Packages = "PackageSubscription-module--Packages--515d8";
export var PackagesInner = "PackageSubscription-module--PackagesInner--402b4";
export var Prices = "PackageSubscription-module--Prices--56753";
export var PricesBlock = "PackageSubscription-module--PricesBlock--8f688";
export var Sash = "PackageSubscription-module--Sash--34311";
export var Selected = "PackageSubscription-module--Selected--bfca9";
export var Silver = "PackageSubscription-module--Silver--c8e06";
export var Subscribe = "PackageSubscription-module--Subscribe--5c508";
export var SubscribeInner = "PackageSubscription-module--SubscribeInner--c82de";
export var SubscribeSide = "PackageSubscription-module--SubscribeSide--bdaf4";
export var Title = "PackageSubscription-module--Title--ce2e9";