import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Package } from '../../Classes/Package';
import * as styles from './PackageSubscription.module.scss';
import goldImg from '../../assets/IMG/Gold.png';
import silverImg from '../../assets/IMG/Silver.png';

type Props = {
  PackageID: string;
  Length: string;
  AdviceLine: string;
  AdviceLineLength: string;
  isPortal: boolean;
  Packs: Package[];
}

const PackageSubscription: React.FC<Props> = ({ PackageID, Length, AdviceLine, AdviceLineLength, isPortal, Packs }) => {
  const [pack, setPackage] = useState<Package>({} as Package);

  useEffect(() => {
    if (PackageID && Length)
      GetData();
  }, [PackageID, Length, Packs]);

  const GetData = async () => {
    let packs: Package[] = Packs;

    if (packs.filter(p => !p.ID).length) {
      packs = Packs;
    }

    if (PackageID) {
      let chosenPack = packs.find(p => p.ID === parseInt(PackageID))
      if (chosenPack)
        setPackage(chosenPack);
    }
  }

  return (<>
    <div className={styles.Subscribe}>
      <div className={styles.SubscribeInner}>
        <div className={`${styles.Packages}`}>
          <h1 className={styles.Title}>Pick a Package: </h1>
          <p>Each Package has its own set of useful documents that will help your business grow, be more independant and more legally compliant. You can find more information on each package here:</p>
          <ul>
            <li><Link to="/Packages/Bronze">Bronze Package</Link></li>
            <li><Link to="/Packages/Silver">Silver Package</Link></li>
            <li><Link to="/Packages/Gold">Gold Package</Link></li>
          </ul>
          {
            Packs.map(p => {
              return isPortal ? <a key={p.Title} onClick={() => setPackage(p)} className={`${styles.PackageBlock} ${p.ID ? "" : styles.Loading} ${styles[p.Title]} ${pack.ID === p.ID ? styles.Selected : ""}`}>
                <h2>
                  {p.Title}
                </h2>

                <img src={p.ID === 2 ? silverImg : p.ID === 3 ? goldImg : ""} />
              </a> : <Link key={p.Title} to={`/Subscribe?PackageID=${p.ID}&PackageLength=0&AdviceID=0&AdviceLength=0`} className={`${styles.PackageBlock} ${p.ID ? "" : styles.Loading} ${styles[p.Title]} ${PackageID && parseInt(PackageID) && parseInt(PackageID) === p.ID ? styles.Selected : ""}`}>
                <h2>
                  {p.Title}
                </h2>

                <img src={p.ID === 2 ? silverImg : p.ID === 3 ? goldImg : ""} />
              </Link>
            })
          }
        </div>

        <div className={`${styles.Prices} ${pack.ID ? "" : styles.Empty}`}>
          <h1 className={styles.Title}>Pick a Tier: </h1>
          <p>
            All our packages, apart from Bronze, are paid in monthly installments, however we want your subscription to be affordable so we have made it so the longer you are with us, the cheaper your package will be.
          </p>
          <p>
            We try and recommend choosing a yearly subscription as each year new laws are released and new policies and documents have be made to keep companies compliant.
          </p>
          <div className={`${styles.PricesBlock} ${styles[pack.Title]} ${Length && parseInt(Length) === 1 ? styles.Selected : ""}`}>
            <h2>
              <small>1 Month</small>
              <b>£{pack.Cost}*</b>
              <small>Per Month</small>
            </h2>

            <ul>
              <li className={styles.Highlight}><p>1 Months</p><span>£{parseFloat(pack.Cost) * 1}</span></li>
              <li><p>3 Months</p><span>£{parseFloat(pack.Cost) * 3}</span></li>
              <li><p>6 Months</p><span>£{parseFloat(pack.Cost) * 6}</span></li>
              <li><p>12 Months</p><span>£{parseFloat(pack.Cost) * 12}</span></li>
            </ul>

            <Link className={styles.Button} to={`/Subscribe?PackageID=${pack.ID}&PackageLength=1&AdviceID=${AdviceLine ? AdviceLine : "0"}&AdviceLength=${AdviceLineLength ? AdviceLineLength : "0"}`}>Select<p>Selected <i><FontAwesomeIcon icon={faCheckDouble} /></i></p></Link>
          </div>
          <div className={`${styles.PricesBlock}  ${styles[pack.Title]} ${Length && parseInt(Length) === 3 ? styles.Selected : ""}`}>
            <h2>
              <small>3 Month</small>
              <b>£{pack.Cost3}*</b>
              <small>Per Month</small>
            </h2>

            <ul>
              <li><p>1 Month</p><span>£{parseFloat(pack.Cost3) * 1}</span></li>
              <li className={styles.Highlight}><p>3 Months</p><span>£{parseFloat(pack.Cost3) * 3}</span></li>
              <li><p>6 Months</p><span>£{parseFloat(pack.Cost3) * 6}</span></li>
              <li><p>12 Months</p><span>£{parseFloat(pack.Cost3) * 12}</span></li>
            </ul>

            <p>Save {(100 - ((parseFloat(pack.Cost3) * 12) / (parseFloat(pack.Cost) * 12) * 100)).toFixed(1)}% Per Year</p>

            <Link className={styles.Button} to={`/Subscribe?PackageID=${pack.ID}&PackageLength=3&AdviceID=${AdviceLine ? AdviceLine : "0"}&AdviceLength=${AdviceLineLength ? AdviceLineLength : "0"}`}>Select<p>Selected <i><FontAwesomeIcon icon={faCheckDouble} /></i></p></Link>
          </div>
          <div className={`${styles.PricesBlock}  ${styles[pack.Title]} ${Length && parseInt(Length) === 6 ? styles.Selected : ""}`}>
            <h2>
              <small>6 Month</small>
              <b>£{pack.Cost6}*</b>
              <small>Per Month</small>
            </h2>

            <ul>
              <li><p>1 Month</p><span>£{parseFloat(pack.Cost6) * 1}</span></li>
              <li><p>3 Months</p><span>£{parseFloat(pack.Cost6) * 3}</span></li>
              <li className={styles.Highlight}><p>6 Months</p><span>£{parseFloat(pack.Cost6) * 6}</span></li>
              <li><p>12 Months</p><span>£{parseFloat(pack.Cost6) * 12}</span></li>
            </ul>

            <p>Save {(100 - ((parseFloat(pack.Cost6) * 12) / (parseFloat(pack.Cost) * 12) * 100)).toFixed(1)}% Per Year</p>

            <Link className={styles.Button} to={`/Subscribe?PackageID=${pack.ID}&PackageLength=6&AdviceID=${AdviceLine ? AdviceLine : "0"}&AdviceLength=${AdviceLineLength ? AdviceLineLength : "0"}`}>Select<p>Selected <i><FontAwesomeIcon icon={faCheckDouble} /></i></p></Link>
          </div>
          <div className={`${styles.PricesBlock}  ${styles[pack.Title]} ${Length && parseInt(Length) === 12 ? styles.Selected : ""}`}>
            <div className={styles.Sash}>
              Best Price!
            </div>

            <h2>
              <small>12 Month</small>
              <b>£{pack.Cost12}*</b>
              <small>Per Month</small>
            </h2>

            <ul>
              <li><p>1 Month</p><span>£{parseFloat(pack.Cost12) * 1}</span></li>
              <li><p>3 Months</p><span>£{parseFloat(pack.Cost12) * 3}</span></li>
              <li><p>6 Months</p><span>£{parseFloat(pack.Cost12) * 6}</span></li>
              <li className={styles.Highlight}><p>12 Months</p><span>£{parseFloat(pack.Cost12) * 12}</span></li>
            </ul>

            <p>Save {(100 - ((parseFloat(pack.Cost12) * 12) / (parseFloat(pack.Cost) * 12) * 100)).toFixed(1)}% Per Year</p>

            <Link className={styles.Button} to={`/Subscribe?PackageID=${pack.ID}&PackageLength=12&AdviceID=${AdviceLine ? AdviceLine : "0"}&AdviceLength=${AdviceLineLength ? AdviceLineLength : "0"}`}>Select<p>Selected <i><FontAwesomeIcon icon={faCheckDouble} /></i></p></Link>
          </div>

          <span>* Prices do not include VAT. VAT of 20% will be added at checkout.</span>
        </div>
      </div>
    </div>
  </>
  );
};

export default PackageSubscription;
