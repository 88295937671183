import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Input from '../Input/input';
import { API_AdviceLines } from '../../Services/ApiRoutes';
import { Fetch } from '../../Services/Fetch';
import * as styles from './AdviceLine.module.scss';
import { AdviceLine as AdvLine } from '../../Classes/AdviceLine';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { Loading } from '../Loading/Loading';
import { LoadingStatus } from '../../Enums/LoadingStatus';

type Props = {
  OnChange: (ID: number, Duration: number) => void;
  ID: number;
  Duration: number;
  Disabled: boolean;
}

const AdviceLineMatrix: React.FC<Props> = ({ OnChange, ID, Duration, Disabled }) => {
  const [employees, setEmployees] = useState<number>(1);
  const [adviceLines, setAdviceLines] = useState<AdvLine[]>([] as AdvLine[]);
  const [adviceLine, setAdviceLine] = useState<AdvLine>({} as AdvLine);
  const [max, setMax] = useState<number>(1);
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.NONE);
  const [isMonth, setIsMonth] = useState<number>(12);

  useEffect(() => {
    GetData();
  }, []);

  useEffect(() => {
    SetAdviceLine();
  }, [employees, adviceLines])

  const SetAdviceLine = () => {
    if (employees) {
      let line: AdvLine | undefined = adviceLines.find(a => parseInt(a.Title.split(" - ")[0]) <= employees && parseInt(a.Title.split(" - ")[1]) >= employees);
      if (line) {
        setAdviceLine(line);
      } else {
        setAdviceLine({} as AdvLine);
      }
    } else {
      setAdviceLine({} as AdvLine);
    }
  }

  const GetData = async () => {
    setLoading(LoadingStatus.LOADING);

    await Fetch(`${API_AdviceLines}`)
      .then((Response: AdvLine[]) => {
        if (Response.length) {
          let maximum: number = 1;
          setAdviceLines(Response.filter((x) => x.ID > 1));

          Response.map((adviceLine: AdvLine) => {
            let maxValue: number = parseInt(adviceLine.Title.split(" - ")[1]);
            if (maxValue > maximum) {
              maximum = maxValue
            }
          });

          setMax(maximum);
        }
      })

    setLoading(LoadingStatus.NONE);
  }

  const AdjustNumber = (Value: number) => {
    return (Math.round(Value * 100) / 100).toFixed(2)
  }

  return (loading === LoadingStatus.LOADING ? <div className={styles.Loading}><Loading /></div> : <div className={styles.Main}>
    <div className={styles.Input}>
      <Input Disabled={Disabled} Type={Disabled ? "textarea" : "number"} Min="0" Max={max.toString()} Placeholder="" Label="Number of Employees" Value={employees.toString()} OnChange={(v) => setEmployees(parseInt(v))} />
    </div>

    {Disabled ? <></> : <div className={`${styles.Prices} ${!adviceLine.ID ? styles.Empty : ""}`}>
      <div className={styles.Tabs}>
        <button type="button" className={isMonth === 1 ? styles.Active : ""} onClick={() => setIsMonth(1)}>Per Month</button>
        <button type="button" className={isMonth === 12 ? styles.Active : ""} onClick={() => setIsMonth(12)}>Per Year</button>
      </div>

      <div className={`${styles.PricesBlock}`}>
        {isMonth === 1 ? <h2>
          1 Month
          <span>
            £{adviceLine.ID ? AdjustNumber(parseFloat(adviceLine.MonthPrice) / employees) : "0.00"}
            <small>Per Employee Per Month</small>
          </span>
        </h2> : <>
          <div className={styles.Sash}>
            Best Price!
          </div>

          <h2>
            12 Months
            <span>
              £{adviceLine.ID ? AdjustNumber(parseFloat(adviceLine.YearPrice) / employees) : "0.00"}
              <small>Per Employee Per Year</small>
            </span>
          </h2>

          <p>Save {(100 - (parseFloat(adviceLine.YearPrice) / (parseFloat(adviceLine.MonthPrice) * 12) * 100)).toFixed(1)}% Per Year</p>
        </>}

        <button className={`${styles.Button} ${(Duration === isMonth && ID === adviceLine.ID) ? styles.Active : ""}`} onClick={() => OnChange(adviceLine.ID ? adviceLine.ID : 0, adviceLine.ID ? isMonth : 0)}>Select<p>Selected <i><FontAwesomeIcon icon={faCheckDouble} /></i></p></button>

        <ul>
          <li><p>Unlimited 24/7 expert HR advice</p><i><FontAwesomeIcon icon={faCheckCircle} /></i></li>
          <li><p>Advice Reports</p><i><FontAwesomeIcon icon={faCheckCircle} /></i></li>
          <li><p>Expert employment tribunal representation</p><i><FontAwesomeIcon icon={faCheckCircle} /></i></li>
          <li><p>Updates on changes in employment law</p><i><FontAwesomeIcon icon={faCheckCircle} /></i></li>
          <li><p>Help with recruitment</p><i><FontAwesomeIcon icon={faCheckCircle} /></i></li>
          <li><p>Help with Policies & Procedures</p><i><FontAwesomeIcon icon={faCheckCircle} /></i></li>
        </ul>
      </div>

      {Duration && ID ? <div className={styles.Selected}>
        <p className={(Duration !== isMonth || ID !== adviceLine.ID) ? styles.Show : ""}>£{adviceLines.find(a => a.ID === ID)?.MonthPrice} Per Employee Per {Duration === 1 ? "Month" : "Year"}</p>
        <button className={styles.Reset} onClick={() => { OnChange(0, 0); setAdviceLine({} as AdvLine); setIsMonth(1); setEmployees(0) }}>Remove Advice Line</button>
      </div> : <></>}
    </div>}
  </div>);
};

export default AdviceLineMatrix;
